
html[dir="rtl"] {
  body * {
    text-align: right;
    font-family: "Noto Sans Arabic" !important;
    // font-size: 98%;
    background-color: #ffffff !important;
  }
  body{
    background-color: #ffffff !important;

  }
  header {
    .user-avatar {
      &.dropdown {
        .dropdown-menu {
          right: auto !important;
          left: 1rem !important;
          .dropdown-item {
            text-align: right;
            .icon-wrap {
              float: right;
              margin-right: 0;
              margin-left: 0.5rem;
            }
          }
        }
      }
    }
    .navbar {
      .navbar-collapse {
        left: auto;
        right: -300px;
        .navbar-nav {
          direction: rtl;
          .nav-item-icon {
            margin-left: 1rem;
            margin-right: 0;
          }
        }
        &.show {
          left: auto;
          right: 0;
        }
      }
    }
  }
  .comp-set {
    .theme-colors {
      margin-left: 0;
      margin-right: 2rem;
    }
    .comp-info {
      .icon {
        margin-left: 0.5rem;
        margin-right: 0;
      }
    }
  }
  .form-list {
    li {
      .list-item-detail {
        h6 {
          line-height: 1.5rem;
        }
      }
      .list-item-action {
        margin-left: 1rem;
        margin-right: auto;
        .dropdown-menu {
          .icon-wrap {
            float: right;
            img {
              margin-left: 0.75rem;
              margin-right: 0;
            }
          }
        }
      }
    }
  }
  .form-view-wrapper {
    .form-page-wrapper {
      .form-page {
        .form-section-wrapper {
          .form-section {
            text-align: right;
          }
        }
      }
    }
  }
}
