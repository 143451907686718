//input

.input-field {
  margin-top: 3rem;
  position: relative;
  background-color: transparent !important;
  input {
    width: 100%;
    height: 31px;
    color: #828282;
    font-size: 23px;
    border: none;
    border-bottom: 2px solid #e0e0e0;
    outline: none;
    background-color: transparent !important;
  }
  .show-password {
    position: absolute;
    top: 1px;
    right: 20px;
    background-color: transparent !important;
  }
}
.form-label {
  width: 100%;
  display: block;
  text-align: left;
  padding: 0 0.5rem;
  margin-bottom: 0;
  font-weight: 500;
}
.required {
  &::after {
    content: "*";
    color: red !important;
    margin-left: 0.1rem;
    text-align: start;
  }
}

.switch {
  position: relative;
  display: inline-block;
  width: 33px;
  height: 4px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  // cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #e0e0e0;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 14px;
  width: 14px;
  left: 4px;
  bottom: -4px;
  background-color: #bdbdbd;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.custom-slider:checked + .slider {
  background-color: #e0e0e0;
}

.custom-slider:focus + .slider {
  box-shadow: 0 0 1px #e0e0e0;
}

.custom-slider:checked + .slider:before {
  -webkit-transform: translateX(16px);
  -ms-transform: translateX(16px);
  transform: translateX(16px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}





//round radio

.round-switch {
  position: relative;
  display: inline-block;
  width: 30px;
  height: 18px;
}

/* Hide default HTML checkbox */
.round-switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */

.round-slider {
  position: absolute;
  // cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #BDBDBD;
  -webkit-transition: .4s;
  transition: .4s;
}

.round-slider:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  left: 2px;
  bottom: 1px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .round-slider {
  background-color: #BDBDBD;
}

input:focus + .round-slider {
  box-shadow: 0 0 1px #BDBDBD;
}

input:checked + .round-slider:before {
  -webkit-transform: translateX(10px);
  -ms-transform: translateX(10px);
  transform: translateX(10px);
}

/* Rounded sliders */
.round-slider.round {
  border-radius: 34px;
}

.round-slider.round:before {
  border-radius: 50%;
}
