.companies-header {
  background-color: #ffffff;
  margin-top: 65px;
  padding-left: 20rem;
  height: 61px;
  align-items: center;
  display: flex;
  gap: 16px;
  font-weight: 700;
  div {
    width: 114px;
    background-color: transparent;
    padding: 10px 14px 10px 14px;
    font-size: 12px;
    line-height: 14px;
    height: 34px;
    text-align: center;
    align-items: center;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
    color: #4f4f4f;
    cursor: pointer;
    &.normal {
      color: #20639b !important;
      background-color: #20639b1a !important;
    }
    &.active {
      background-color: rgba(32, 99, 155, 0.1) !important;
      color: #20639b !important;
    }
  }
}
.companies-main-wrap {
  background-color: #ffffff;
  margin-top: 10px;
  margin-left: 17rem;
  height: 100% !important;
  padding-left: 2rem;
  margin-right: 0.3rem;
  padding-right: 2rem;
  padding-top: 2rem;

  .searchIcon-wrap {
    cursor: pointer;
  }

  .filters {
    background-color: transparent;
    align-items: center;
    display: flex;
    gap: 16px;
    display: flex;
    justify-content: start;
    color: #4f4f4f;
    //  height: 8rem;
    .filter-div {
      border-radius: 6px;
      padding: 10px 14px 10px 14px !important;
      font-size: 12px !important;
      line-height: 14px;
      height: 34px !important;
      text-align: center;
      &.normal {
        color: #4f4f4f;
        background-color: #f2f2f2;
        font-weight: 700;
        cursor: pointer;
      }
      &.active {
        color: rgba(255, 255, 255, 1);
        background-color: rgba(32, 99, 155, 1);
        font-weight: 700;
        cursor: pointer;
      }
      &.disabled {
        color: #bdbdbd;
        background-color: #f2f2f2;
        height: max-content !important;
        white-space: nowrap;
        pointer-events: none !important;
      }
    }
    .filter-wrap {
      border-radius: 6px;
      padding: 10px 14px 10px 14px;
      font-size: 12px;
      line-height: 14px;
      height: auto !important;
      text-align: center;
      &.normal {
        color: #4f4f4f;
        background-color: #f2f2f2;
        font-weight: 700;
        cursor: pointer;
      }
      &.active {
        color: rgba(255, 255, 255, 1);
        background-color: rgba(32, 99, 155, 1);
        font-weight: 700;
        cursor: pointer;
      }
      &.disabled {
        color: #bdbdbd;
        background-color: #f2f2f2;
        height: max-content !important;
        white-space: nowrap;
      }
    }
    .group-action-dropdown {
      position: absolute !important;
      top: 36px !important;
      left: 0;
      width: 333px !important;
      height: 135px !important;
      border-radius: 5px !important;
      background-color: #ffffff;
      padding: 0 !important;
      padding-top: 22px !important;
      z-index: 100 !important;

      .icon-wrap {
        display: flex !important;
        align-items: center !important;
        justify-content: start !important;
        gap: 1rem !important;
        font-size: 13px;
        font-weight: 500;
        position: relative !important;
        padding-top: 7px !important;
        padding-bottom: 7px !important;
        img {
          padding-left: 16px !important;
        }

        &:hover {
          background-color: rgba(242, 242, 242, 0.3) !important;
        }
      }
    }
  }
}
.companies-full {
  margin-left: 1rem !important;
}

.companies-footer {
  background-color: #ffffff;
  margin-top: 10px;
  padding-left: 18rem;
  height: 40px;
  align-items: center;
  display: flex;
  color: #828282 !important;
  font-size: 12px;
  font-weight: 14;
  line-height: 18px;
}

.custom-multi-select {
  position: relative;
  width: 239px;
  // padding: 0px !important;
  text-align: start !important;
  // margin-left: -6px !important;
  .dropdown {
    position: relative;
    width: 100%;
  }
  .form-lable {
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    color: #333333 !important;
  }
  .options {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1;
    background-color: white;
    border: 1px solid #ccc !important;
    border-top: none;
    max-height: 200px;
    overflow-y: auto;
  }

  .options label {
    display: block;
    padding: 5px;
  }

  /* Additional styling for better appearance */
  .selected-items {
    margin-bottom: 10px;
  }

  .selected-items span {
    margin-right: 5px;
    background-color: #f0f0f0;
    padding: 2px 5px;
    border-radius: 3px;
  }

  .custom-multi-select {
    position: relative !important;
  }

  .select-field {
    border: 1px solid rgba(224, 224, 224, 1);
    // padding: 2px;
    min-height: 36px;
    cursor: pointer;
    display: flex;
    flex-wrap: wrap !important;
    align-items: center !important;
    gap: 5px !important;
    justify-content: start !important;
    margin-top: 5px !important;
    z-index: 100;
    height: fit-content !important;
    padding-left: 5px !important;
    padding-top: 5px !important;
    text-align: center !important;
    padding-bottom: 0px !important;

    .selectedSubItems {
      text-align: center !important;
      background-color: #20639b1a !important;
      color: #333333 !important;
      border-radius: 6px;
      font-size: 12px;
      font-weight: 400 !important;
      line-height: 15.92px !important;
    }
  }

  .dropdown {
    position: absolute;
    bottom: 100%; /* Position the dropdown above the select field */
    left: 0;
    width: 100%;
    background-color: #fff;
    border: 1px solid #ccc;
    border-top: none;
    border-radius: 0 0 5px 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    z-index: 10;
  }

  .search-input {
    width: 209px !important;
    margin-left: 15px !important;
    margin-top: 10px !important ;
    border: 1px solid rgba(224, 224, 224, 1); /* Add border to the search input */
    padding: 5px;
    // margin-left: 20px;
    // margin-top: 30px !important;
    margin-bottom: 8px;
    background-color: #f2f2f2 !important;
    border-radius: 6px;
    height: 30px;
    color: #bdbdbd !important;
    font-size: 13px;
    line-height: 16px;
    font-weight: 400;
    border: none !important;
    outline: none !important;
    padding-left: 37px;
    padding-right: 37px;
  }

  .options-container {
    min-height: 197px; /* Limit max height of options */
    overflow-y: auto; /* Add scroll for overflow */
    // padding: 0px !important;
    padding-left: 3px !important;
    .checkbox {
      color: #bdbdbd;
      width: 15px;
      height: 15px;
      appearance: none;
      -webkit-appearance: none;
      -moz-appearance: none;
      border: none !important;
      border-radius: 5px;
      outline: none;
      cursor: pointer;
    }
    .checkbox:checked {
      background: url(../images/icon-checkbox-check.svg) center center no-repeat !important;
      background-color: #20639b !important;
      outline: none !important;
    }
  }
  .select-all {
    width: 100%;
    padding: 5px;
    margin-top: 10px;
    padding-left: 9px !important;
    padding-bottom: 15px !important;
    .checkbox {
      color: #bdbdbd;
      width: 15px;
      height: 15px;
      appearance: none;
      -webkit-appearance: none;
      -moz-appearance: none;
      border: none !important;
      border-radius: 5px;
      outline: none;
      cursor: pointer;
    }
    .checkbox:checked {
      background: url(../images/icon-checkbox-check.svg) center center no-repeat !important;
      background-color: #20639b !important;
      outline: none !important;
    }
  }

  .option-label {
    width: 100%; /* Set width for options */
    padding: 5px;
    font-size: 14px;
    font-weight: 500;
    // padding: 0px !important;
  }
  .label-checkbox {
    margin: 0px 12px;
  }
  .dropdown-icon {
    margin-left: 5px;
    position: relative !important;
  }

  .open {
    transform: rotate(180deg);
  }
  .multi-select-dropdown {
    width: 100%;
    border-radius: 5px;
    box-shadow: 0px 0px 30px 0px #00000033;
    position: absolute;
    background-color: #fff !important;
    z-index: 10;
    // height: 321px !important;
  }
}

.checkbox {
  color: #bdbdbd;
  width: 15px;
  height: 15px;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  border: none !important;
  border-radius: 5px;
  outline: none;
  cursor: pointer;
}
.checkbox:checked {
  background: url(../images/icon-checkbox-check.svg) center center no-repeat !important;
  background-color: #20639b !important;
  outline: none !important;
}
.table-responsive {
  min-height: 400px !important;
}
.table-main {
  width: 100% !important;
  color: #828282 !important;
  height: max-content !important;
  tbody {
    border-left: none !important;
    tr {
      &:hover {
        background-color: #f2f2f280 !important;
      }
    }
  }
  .header {
    height: 100px !important;
  }
}
table {
  border-collapse: collapse !important;
  border-left: none !important;
}
.confirmation-modal {
  width: 333px;
  background-color: rgba(255, 255, 255, 1);
  color: rgba(51, 51, 51, 1);
  min-height: 135px;
  // max-width: fit-content;
  height: max-content;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1001 !important;
  label {
    margin-left: 10px !important;
  }
  .reason {
    margin-left: 10px !important;
    margin-right: 10px !important;
    textarea {
      width: 100%;
      height: 125px;
      resize: none;
      border: 1px solid #e0e0e0;
      border-radius: 5.46px;
      color: #bdbdbd;
      font-size: 13px;
      margin: 0px;
      padding: 0px !important;
      padding-left: 1rem !important;
      padding-top: 1rem !important;
      outline: none !important;
    }
  }
  p {
    margin-top: 16px !important;
    text-align: start !important;
    line-height: 21px !important;
    font-size: 14px;
    font-weight: 500;
    width: 230px !important;
    white-space: initial;
    margin-left: 10px !important;
  }
  .actions-wrap {
    font-size: 14px;
    font-weight: 400;
    bottom: 0;
    position: absolute;
    right: 0;
    margin-bottom: 10px;

    button {
      border: none;
      outline: none;
      background-color: transparent !important;
      color: rgba(130, 130, 130, 1);
    }
    .action-btn {
      font-weight: 500 !important;
    }
  }
  .email-wrap {
    // margin-left: 10px !important;
    padding: 5px !important;
    text-align: start !important;

    div {
      .send-email {
        color: #333333 !important;
        font-size: 17px;
        font-weight: 400;
        line-height: 20px;
        margin-left: 10px;
      }
      .send-btn {
        color: #20639b !important;
        font-size: 14px;
        font-weight: 600;
      }
    }

    .subject-input {
      width: 302px !important;
      height: 45px;
      border: 1px solid #e0e0e0;
      outline: none;
      border-radius: 5.46px;
      padding: 0px !important;
      padding-left: 1rem !important;
      margin-left: 10px !important;
      margin-right: 10px !important;
      // margin: 0px !important;
      // margin-left: 5px !important;
    }
  }
}

.success-modal-main {
  width: 317px;
  height: 48px !important;
  border-radius: 10px;
  color: rgba(79, 79, 79, 1);
  font-size: 13px;
  font-weight: 400;
  line-height: 17.71px;
  margin-top: 12px !important;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: start;
  background-color: #ffffff !important;
  p {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .message-wrap {
    display: flex !important;
    align-items: center !important;
    gap: 15px;
    img {
      margin-left: 20px;
    }
  }
}
.logout-btn {
  border: none;
  outline: none;
  font-size: 14px;
  color: #ffffff;
  background-color: transparent;
}
.main-pagination-wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 3rem;
  padding-bottom: 1rem !important;
  .page-btns-wrap {
    display: flex;
    align-items: center;
    justify-content: start;
    gap: 0.5rem !important;
  }
  .first-page-btn,
  .prev-btn,
  .last-page-btn,
  .next-btn {
    background-color: #f2f2f2 !important;
    height: 29px;
    width: 29px;
    text-align: center !important;
    border-radius: 5.46px;
  }
  .pages-wrap {
    color: #4f4f4f !important;
    background-color: transparent !important;
    font-size: 12px;
    font-weight: 400;
    line-height: 13px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: start;
    gap: 0.5rem;

    div {
      height: 29px;
      width: 29px;
      text-align: center !important;
      border-radius: 5.46px;
      display: flex;
      justify-content: center;
      align-items: center;
      &.active {
        background-color: #20639b !important;
        color: #ffffff !important;
      }
    }
  }
  select {
    outline: none !important;
    border: none !important;
    background-color: #f2f2f2 !important;
    width: 60px !important;
    height: 29px !important;
    font-size: 13px !important;
    color: #4f4f4f !important;
  }
  .page-show {
    color: #4f4f4f;
    font-size: 12px;
    line-height: 19.5px;
    margin-left: 1rem;
  }
}

.table-action-dropdown {
  position: absolute !important;
  top: 25px !important;
  right: 57px;
  width: 333px !important;
  height: 135px !important;
  border-radius: 5px !important;
  background-color: #ffffff;
  color: #4f4f4f;
  padding-top: 10px !important;
  z-index: 99 !important;
  .icon-wrap {
    display: flex !important;
    align-items: center !important;
    justify-content: start !important;
    gap: 1rem !important;
    font-size: 13px !important;
    font-weight: 500 !important;
    position: relative !important;
    padding-top: 7px;
    padding-bottom: 7px;
    cursor: pointer;

    img {
      padding-left: 28px !important;
    }

    &:hover {
      background-color: rgba(242, 242, 242, 0.3) !important;
    }
  }
}
.company-overview-action-dropdown {
  @extend .table-action-dropdown;
  // width: 90% !important;
  // width:18vw !important;
  top: 47px !important;
  right: 12px !important;
}
.confirmation-table-modal {
  width: 333px;
  background-color: rgba(255, 255, 255, 1);
  color: rgba(51, 51, 51, 1);
  min-height: 135px;
  // max-width: fit-content;
  padding: 10px 14px 10px 14px !important;
  height: max-content;
  position: absolute;
  top: -13px;
  left: 0;
  z-index: 1001 !important;
  .reason {
    textarea {
      width: 100%;
      height: 125px;
      resize: none;
      border: 1px solid #e0e0e0;
      border-radius: 5.46px;
      color: #bdbdbd;
      font-size: 13px;
      outline: none !important;
      padding-left: 1rem !important;
      padding-top: 1rem !important;
    }
  }
  p {
    margin-top: 16px !important;
    text-align: start !important;
    line-height: 21px !important;
    font-size: 14px;
    font-weight: 500;
    width: 230px !important;
    white-space: initial;
  }
  .actions-wrap {
    font-size: 14px;
    font-weight: 400;
    bottom: 0;
    position: absolute;
    right: 10px;
    margin-bottom: 10px;

    button {
      border: none;
      outline: none;
      background-color: transparent !important;
      color: rgba(130, 130, 130, 1);
    }
    .action-btn {
      font-weight: 500 !important;
    }
  }
  .email-wrap {
    margin: 0px !important;
    text-align: start !important;
    div {
      .send-email {
        color: #333333 !important;
        font-size: 17px;
        font-weight: 400;
        line-height: 20px;
      }
      .send-btn {
        color: #20639b !important;
        font-size: 14px;
        font-weight: 600;
      }
    }

    .subject-input {
      width: 100%;
      height: 45px;
      border: 1px solid #e0e0e0;
      outline: none;
      border-radius: 5.46px;
      padding-left: 1rem !important;
      padding-right: 20px !important;
    }
  }
}
.toggle-icon {
  transform: rotate(180deg) !important;
}
.toggle-icon-up{
  transform: rotate((0deg)) !important;
}

//invoice styles
.invoice-main-wrapper {
  background-color: #f2f2f2;
  display: flex;
  align-items: center;
  justify-content: center;
  .invoice-wrapper {
    margin-left: 2rem;
    margin-right: 2rem;
    margin-top: 3rem;
    background-color: #fff;
    padding: 1rem;

    .sub-wrap {
      display: grid;
      grid-template-columns: 1fr 1fr;
      color: #333333;
      font-size: 14px;
      font-weight: 400;
      line-height: 16px;
      .main-title {
        font-size: 25px !important;
        font-weight: 500 !important;
      }
    }
    .total-wrap {
      font-size: 16px;
      color: #333333;
      // display: grid;
      // grid-template-columns: 8fr auto;
      // place-items: end;
      display: flex;
      align-items: start;
      justify-content: end;
      gap: 5rem;
      margin-right: 4rem;
      
      .highlighted {
        font-weight: 700;
      }
    }
    .hr {
      width: 300px;
      height: 1px;
      background-color: #e0e0e0;
      margin-top: 3rem;
    }
    .small-hr {
      @extend .hr;
      width: 230px !important;
      margin-right: 4rem;
    }
    .qr-wrap {
      width: 100% !important;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 5rem;
      margin-bottom: 5rem;
    }
  }
}
