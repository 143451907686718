.login-wrapper {
   height: 100vh;
   width: 100%;
   display: flex;
   justify-content: center;
   align-items: center;
   .login-container {
     text-align: center;
     .login-heading {
       color: #4f4f4f;
       font-size: 36px;
     }
     .login-subheading {
       color: #4f4f4f;
       font-size: 18px;
       margin-top: 30px;
       line-height: 21px;
     }
     .reset-subheading{
      width: 365px;
      color: #4f4f4f;
      font-size: 18px;
      line-height: 21px;
     margin: auto;
     margin-top: 30px;

    }
  
     .form-controls {
       margin-top: 2rem;
       border: none;
      
       .reset-email{
        width: 458px !important;
       }
       .btn-wrapper {
         display: flex;
         justify-content: space-between;
         align-items: start ;
         margin-top: 4rem;
 
         p {
           font-size: 18px;
           color: #828282;
           line-height: 21px;
           text-align: start;
           cursor: pointer;
         
         }
         button:disabled {
           width: 173px;
           height: 70px;
           background-color: #bdbdbd;
           color: #ffffff;
           font-size: 24px;
           text-align: center;
           border: none;
           border-radius: 10px;
           line-height: 21px;
           font-weight: 300;
         }
         button{
            width: 173px;
            height: 70px;
            color: #ffffff;
            font-size: 24px;
            text-align: center;
            border: none;
            background-color: #20639B;
            border-radius: 10px;
            line-height: 21px;
            font-weight: 300;
            cursor: pointer;
         }
     
       }
   
       .robot-wrapper{
         display: flex;
         justify-content: start;
         align-items: start;
         gap: 10px;
         margin-top: 3rem;
      .checkbox{
            color: #BDBDBD;
            width: 15px;
            height: 15px;
            appearance: none;
            -webkit-appearance: none;
            -moz-appearance: none;
            border: 1px solid #BDBDBD !important;
            border-radius: 5px;
            outline: none;
            cursor: pointer;
         }
         .checkbox:checked{
            background: url(../images/icon-checkbox-check.svg) center center no-repeat !important;
            background-color: #20639b !important;
            outline: none !important;
         }
     
         div{
            color: #BDBDBD;
            font-size: 16px !important;
            line-height: 16px;
            
         }
       }
       .btn-reset-wrapper{
         margin-top: 4rem;
         display: flex;
         gap: 10px;
         justify-content: center !important;
         align-items: end !important;
         font-weight: 300;
        
         button{
            width: 173px;
            height: 70px;
            color: #ffffff;
            font-size: 24px;
            text-align: center;
            border: none;
            background-color: #20639B;
            border-radius: 10px;
            line-height: 21px;
            font-weight: 300;
            cursor: pointer;
         }
         button:disabled{
          background-color: rgba(189, 189, 189, 1) !important;
          color: #ffffff !important;
         }
         .back-btn{
            width: 173px;
            height: 70px;
            color: #20639B;
            font-size: 24px;
            text-align: center;
            background-color:transparent !important ;
            border: 1px solid #20639B;
            border-radius: 10px;
            line-height: 21px;
            font-weight: 300;
            cursor: pointer;
            
         }
       }
    
     }
   }

 }
 .error {
  color: red  !important;
  font-size: 12px;
  text-align: start;
}