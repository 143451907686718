header {
  position: fixed;
  top: 0;
  width: 100%;
  // height: 65px;
  .navbar {
    background-color: #20639b;
    position: relative;
    padding: 0px !important;
    
    height: 65px;
    .navbar-toggler {
      border: none;
      outline: none;
      z-index: 100;

    }
    .selected-option{
      color: #ffffff;
      font-size: 18px;
      font-weight: 400;
    }
    .navbar-collapse {
      left: auto;
      right: -300px;
      position: fixed;
      left: -300px;
      width: 290px;
      top: 0;
      height: 100vh !important;
      transform: translate(0);
      padding: 5rem 0rem 0rem;
      background-color: #20639b;
      transition: 0.15s ease;
      h4 {
        color: #FFFFFF;
        font-weight: 500;
        line-height: 21px;
        font-size: 20px;
      }
      .navbar-nav {
        // direction: ltr;
        padding-top: 30px;
        color: #FFFFFF;
        width: 100% !important;
        .avatar-wrap{
          width: 40px;
          max-width: 40px;
          height: 40px;
          min-height: 40px;
          overflow: hidden;
          border-radius: 100%;
          display: inline-block;
          font-size: 11px;
          line-height: 15px;
        }
        .nav-item {
          padding-top: .6rem;
          padding-bottom: .6rem;
          font-size: 18px;
          line-height: 27px;
          font-weight: 400;
          &:hover {
            // background-color: #ffffff;
            // color: #20639B;
          }
          &.active{
            background-color: #ffffff;
            color: #20639B;
          }
          width: 100% !important;
          // margin-top: .3rem;
        }
        .nav-item-icon {
          margin-left: 1rem;
          margin-right: 0;
          padding-left: 15px;
          padding-right: 10px;
        }
      }
      &.show {
        left: 0;
        right: auto;
        // width: 100% !important;
        width: 265px !important;
      }
    }
  }
}
